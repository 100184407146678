body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
path, rect, p {
  transition: 0.8s;
}

.headerContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 80px;
  background-color: #2E5BFF88;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  z-index: 270;
   box-shadow: 1px 2px 7px 0px black;
}

.inputContainer{
  display: flex;
  flex-direction: row;

}

.headerSearchInput{
  width: 620px;
  height: 40px;
  left: 77px;
  top: 20px;

  /* 1 */

  background: #FFFFFF;
  /* 3 */
box-shadow: inset 1px 2px 7px 0px black;
  border: 1px solid #8798AD;
  box-sizing: border-box;
  border-radius: 40px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}

.round{
  border-color: #8888;
  border-width: 1px;
  border-radius: 200px;
  background-color: #8882;
  position: absolute;
  z-index: 6;
  box-shadow: 1px 2px 7px 0px black;
  transition: 1s;
  cursor: pointer;
} p { 
  font-weight: 500;
}

.round-disappearing {
  /* animation: round 1s; */
  transform: scale(3);
  opacity: 0;
  pointer-events: none;
}

.history {
  position: absolute;
  right: 0px;
  top: 200px;
  display: flex; 
  flex-direction: column;
  z-index: 6;
  justify-content: flex-start;
  align-items: flex-end;
  width: 200px;
  height: auto;
  min-height: 28px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 1px 2px 7px 0px black;
  transition: 1s;
  overflow: hidden;
  padding: 8px;
}

.history a {
  color: #dde;
  font-size: 18px;
  height: auto;
  min-width: 140px;
  padding: 2px;
  transition: 1s;
  text-decoration: none;
  font-weight: 500;
  text-align: right;
}

.button {
  background-color: #2f2f47;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 7px 0px black;
  cursor: pointer;
}

.arrow {
  position: absolute;
  z-index: 6;
  transition: 1s;
  cursor: pointer;
} 

.arrow div {
  transition: 1s;
}

@keyframes round {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    transform: scale(5);
    opacity: 0.3
  }
  to {
    transform: scale(5);
    opacity: 0;
  }
}
